'use strict';

import Headroom from 'headroom.js'
import Component from '../../base/Component';

export default class Header extends Component {
    private header: HTMLElement | null;
    private headroom: null;
    constructor(props: object = {}) {
        super(props)
        this.header = document.querySelector('header');
    }

    init() {
        const headroom = new Headroom(this.header, { offset : 175 });
        headroom.init();
    }
}
