import 'lazysizes'
import 'alpinejs'
import './static.assets'

import HeadroomJS from './components/ui/Headroom'
import VenveoApplication from "./base/VenveoApplication"

const application = new VenveoApplication();

application.registerComponent('headroomjs', 'header', HeadroomJS);
application.registerComponent('glightbox', '.glightbox', 'ui/Glightbox');

application.registerComponent('brand-slider', '[data-brand-slider]', 'ui/sliders/BrandSlider')
application.registerComponent('testimonial-slider', '[data-testimonial-slider]', 'ui/sliders/TestimonialSlider')
application.registerComponent('card-slider', '[data-card-slider]', 'ui/sliders/CardSlider')

application.init().then(() => {
    console.log('Site is ready.')
})

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
